import React from 'react'
import { Helmet } from "react-helmet"
import logo from "../../assets/images/percorso-p-logo-blue.png"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Percorso - Distance Learning, Field Education, and Program Management made easy.</title>
                <meta name="description" content="Percorso - Distance Learning, Field Education, and Program Management made easy." />
                <meta name="og:title" property="og:title" content="Percorso - Distance Learning, Field Education, and Program Management made easy."></meta>
                <meta name="twitter:card" content="Rewy - React Gatsby IT Startup Templates Collection"></meta>
                <link rel="canonical" href=""></link>
                <meta property="og:image" content={logo} />
            </Helmet>
        </div>
    )
}

export default SEO
